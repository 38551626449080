import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { Link } from "gatsby";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { Section, SectionPageTitle, Container } from "../components/Section";
import {
  Nodata,
  NodataDesc,
  NodataMedia,
  NodataGrid,
  NodataItem,
} from "../components/Nodata";
import { CloseIcon } from "../components/Icons";
import { Context } from "../components/context";
import DefaultButton from "../components/Button/DefaultButton";
import PrimaryButton from "../components/Button/PrimaryButton";

const CardDefault = styled.div`
  background-color: #f6f6f6;
  color: #373636;
  height: 100%;
`;
const CardDefaultTitle = styled.h2`
  font-size: 20px;
  line-height: 30px;
  color: #373636;
  @media (min-width: 768px) {
    font-size: 24px;
    line-height: 34px;
  }
  @media (min-width: 992px) {
    font-size: 28px;
    line-height: 38px;
  }
`;
const CardDefaultBody = styled.div`
  padding: 30px 15px;
  @media (min-width: 992px) {
    padding: 40px 20px;
  }
  @media (min-width: 1200px) {
    padding: 50px 30px;
  }
  @media (min-width: 1600px) {
    padding: 60px 40px;
  }
`;

const PriceTable = styled.div`
  margin-bottom: 30px;
`;
const PriceRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:last-child {
    border-top: 2px solid #e5e5e5;
  }
`;
const PriceCol = styled.div`
  padding: 15px 10px;
`;
const PriceLabel = styled.div`
  font-weight: 400;
  color: #373636;
  font-size: 18px;
  min-width: 100px;
  @media (min-width: 1200px) {
    font-size: 20px;
  }
  @media (min-width: 1600px) {
    font-size: 22px;
  }
  > stron {
    font-weight: 700;
  }
`;
const SubTotal = styled.div``;
const CartTotal = styled.div`
  font-weight: 700;
  color: #e9242a;

  font-size: 16px;
  line-height: 30px;
  @media (min-width: 768px) {
    font-size: 18px;
    line-height: 30px;
  }
  @media (min-width: 992px) {
    font-size: 20px;
    line-height: 30px;
  }
`;
const Total = styled.div`
  font-weight: 700;
  color: #e9242a;

  font-size: 28px;
  line-height: 32px;
  @media (min-width: 1200px) {
    font-size: 32px;
    line-height: 36px;
  }
`;
const PriceAction = styled.div`
  pointer-events: ${(props) => (props.term ? "unset" : "none")};
  opacity: ${(props) => (props.term ? "1" : "0.5")};

  a {
    display: block;
    width: 100%;
  }
  .btn {
    width: 100%;
  }
`;
const CartPanel = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
`;
const CartLeftPanel = styled.div`
  position: relative;
  width: 100%;
  padding: 0 15px;
  @media (min-width: 992px) {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
`;
const CartRightPanel = styled.div`
  position: relative;
  width: 100%;
  padding: 0 15px;
  @media (min-width: 992px) {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  /* .isSticky{
        height: calc(100% - 64px);
        @media(min-width: 1200px){
            height: calc(100% - 100px);
        }
    } */
`;
const CartInfo = styled.div`
  padding: 30px 0;
  @media (min-width: 992px) {
    padding: 40px 0;
  }
  @media (min-width: 1200px) {
    padding: 50px 0;
  }
  @media (min-width: 1600px) {
    padding: 60px 0;
  }
`;

const CartTable = styled.div`
  margin-bottom: 30px;
  font-weight: 600;
  @media (min-width: 768px) {
    font-size: 18px;
    line-height: 28px;
  }
`;
const CartRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dbdbdb;
  @media (max-width: 575.98px) {
    flex-wrap: wrap;
  }
`;
const CartThead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 4px solid #dbdbdb;
  font-size: 18px;
  line-height: 28px;
  font-weight: 700;
  @media (max-width: 575.98px) {
    display: none;
  }
`;
const CartCol = styled.div`
  max-width: 120px;
  width: 100%;
  text-align: center;
  padding: 15px 5px;
  font-size: 16px;
  line-height: 24px;
  @media (min-width: 1200px) {
    padding: 20px 10px;
  }
  &:first-child,
  &:nth-child(2) {
    max-width: 220px;
    width: 100%;
    text-align: left;
  }
  @media (max-width: 575.98px) {
    flex: 0 0 50%;
    max-width: 50%;
    &:last-child {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  &:last-child {
    text-align: right;
  }
  .form-control {
    width: 40px;
    text-align: center;
    padding: 8px 5px;
  }
  &.amount {
    display: flex;
    align-items: center;
    @media (min-width: 576px) {
      justify-content: space-between;
    }
    button {
      padding: 0 10px;
    }
  }
  .cart-link {
    position: relative;
    font-size: 14px;
    line-height: 20px;
    color: #e9242a;
    &:after {
      -webkit-transition: -webkit-transform 0.3s;
      transition: transform 0.3s;
      background: rgba(54, 54, 54, 0.4);
      content: "";
      position: absolute;
      top: 110%;
      left: 0;
      width: 100%;
      height: 2px;
      -webkit-transform: scaleX(0);
      transform: scaleX(0);
    }
    &:hover {
      color: #373636;
      &:after {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
      }
    }
  }
`;
const CartProduct = styled.div`
  max-width: 290px;
  width: 100%;
`;
const CartAction = styled.div`
  margin: 0 0 30px 0;
  .cart-link {
    position: relative;
    font-size: 16px;
    line-height: 28px;
    color: #373636;
    &:after {
      -webkit-transition: -webkit-transform 0.3s;
      transition: transform 0.3s;
      background: rgba(233, 36, 42, 0.4);
      content: "";
      position: absolute;
      top: 110%;
      left: 0;
      width: 100%;
      height: 2px;
      -webkit-transform: scaleX(0);
      transform: scaleX(0);
    }
    &:hover {
      color: #e9242a;
      &:after {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
      }
    }
  }
`;
const TermsHeader = styled.div`
  font-size: 18px;
  font-weight: 700;
  line-height: 34px;
  padding: 5px 0;
`;
const TermsContent = styled.div`
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 260px;
  transition: all 0.5s;
  margin-bottom: 20px;
  > p,
  > ul {
    font-size: 14px;
    line-height: 24px;
    color: #000;
  }
  /* Scrollbar Styling */
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background-color: #ebebeb;
  }

  &::-webkit-scrollbar-thumb {
    background: #6d6d6d;
  }
`;

const TermCloseBtn = styled.button`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  top: 0;
  width: 28px;
  height: 28px;
  cursor: pointer;
  z-index: 1;
  background-color: #484b58;
  transition: all 0.5s ease-in-out;
  svg {
    width: 12px;
    height: 12px;
    fill: #fff;
  }
  &:hover {
    background-color: #161420;
    svg {
      fill: #fff;
    }
  }
`;

const Terms = styled.div`
  position: relative;
  font-size: 16px;
  line-height: 28px;
  background-color: #fff;
  padding: 15px;
  margin-bottom: 15px;
  .form-check {
    margin: 0;
    button {
      color: #ed1c24;
      &:hover {
        color: #373636;
      }
    }
  }
`;

const CartList = () => {
  const [showTerms, setShowTerms] = useState(false);
  const [termCheck, setTermCheck] = useState(false);
  const [subTotal, setSubTotal] = useState();
  const [total, setTotal] = useState();
  const { cartData, setCartData } = useContext(Context);

  const ToggleTerms = () => {
    setShowTerms(!showTerms);
  };
  const increase = (product) => {
    const increaseCartData = cartData.map((cartItem) =>
      cartItem.id === product.id
        ? { ...cartItem, count: cartItem.count + 1 }
        : cartItem
    );
    setCartData(increaseCartData);
  };
  const decrease = (product) => {
    const decreaseCartData = cartData.map((cartItem) =>
      cartItem.id === product.id
        ? { ...cartItem, count: cartItem.count > 1 ? cartItem.count - 1 : 1 }
        : cartItem
    );
    setCartData(decreaseCartData);
  };

  const removeItem = (id) => {
    const removeItemCartData = cartData.filter(
      (cartItem) => cartItem.id !== id
    );
    setCartData(removeItemCartData);
  };

  useEffect(() => {
    let subTotalPrice = 0;
    cartData?.map((cartItem) => {
      subTotalPrice = subTotalPrice + cartItem.price * cartItem.count;
    });
    setSubTotal(subTotalPrice);
    setTotal(subTotalPrice);
  }, [cartData, setCartData]);

  return (
    <CartPanel>
      <CartLeftPanel>
        <CartInfo>
          <SectionPageTitle textAlign="left" className="h4 mb-40">
            Shopping Cart
          </SectionPageTitle>
          {cartData?.length > 0 ? (
            <>
              <CartTable>
                <CartThead>
                  <CartCol>Product Details</CartCol>
                  <CartCol>&nbsp;</CartCol>
                  <CartCol>Price</CartCol>
                  <CartCol>Quantity</CartCol>
                  <CartCol>Total</CartCol>
                </CartThead>
                {cartData?.map((item, i) => {
                  return (
                    <CartRow key={i}>
                      <CartCol>
                        <CartProduct>
                          <GatsbyImage
                            image={item?.image?.gatsbyImageData}
                            alt={item.title}
                          />
                        </CartProduct>
                      </CartCol>
                      <CartCol>{item.name}</CartCol>
                      <CartCol>
                        ${parseInt(item.price).toLocaleString("en-US")}
                      </CartCol>
                      <CartCol className="amount">
                        <button onClick={() => decrease(item)}>-</button>
                        <input
                          type="text"
                          value={item.count}
                          id="quantity"
                          className="form-control"
                          name="qty"
                          aria-label="Product quantity"
                          size="4"
                          min="0"
                          max=""
                          disabled
                        />
                        <button onClick={() => increase(item)}>+</button>
                      </CartCol>
                      <CartCol>
                        <CartTotal>
                          $
                          {parseInt(item.price * item.count).toLocaleString(
                            "en-US"
                          )}
                        </CartTotal>
                        <button
                          type="button"
                          className="cart-link"
                          onClick={() => removeItem(item.id)}
                        >
                          Remove
                        </button>
                      </CartCol>
                    </CartRow>
                  );
                })}
              </CartTable>
              <CartAction>
                <Link to="/shop" className="cart-link">
                  Continue Shopping
                </Link>
              </CartAction>
            </>
          ) : (
            <>
              <Nodata>
                <NodataGrid>
                  <NodataItem>
                    <NodataMedia>
                      <StaticImage
                        src="../images/empty-cart.png"
                        alt="empty-cart"
                      />
                    </NodataMedia>
                  </NodataItem>
                  <NodataItem>
                    <NodataDesc mb="30px">
                      <p>There is no product in the cart</p>
                      <Link to="/shop">
                        <PrimaryButton text="Continue Shopping" size="md" />
                      </Link>
                    </NodataDesc>
                  </NodataItem>
                </NodataGrid>
              </Nodata>
            </>
          )}
        </CartInfo>
      </CartLeftPanel>
      <CartRightPanel>
        <div className="isSticky">
          <CardDefault>
            <CardDefaultBody>
              <CardDefaultTitle>Order Summary</CardDefaultTitle>
              <PriceTable>
                <PriceRow className="price-row">
                  <PriceCol>
                    <PriceLabel>SUBTOTAL</PriceLabel>
                  </PriceCol>
                  <PriceCol>
                    <SubTotal>
                      ${parseInt(subTotal).toLocaleString("en-US")}
                    </SubTotal>
                  </PriceCol>
                </PriceRow>
                <PriceRow className="price-row">
                  <PriceCol>
                    <PriceLabel>
                      <strong>TOTAL</strong>
                    </PriceLabel>
                  </PriceCol>
                  <PriceCol>
                    <Total>${parseInt(total).toLocaleString("en-US")}</Total>
                  </PriceCol>
                </PriceRow>
              </PriceTable>
              {cartData?.length > 0 && (
                <Terms className={showTerms ? "" : "show"}>
                  {showTerms && (
                    <>
                      <TermsHeader className="terms-header">
                        Pricing Disclaimer{" "}
                        <TermCloseBtn onClick={() => setShowTerms(false)}>
                          <CloseIcon />
                        </TermCloseBtn>
                      </TermsHeader>
                      <TermsContent className="terms-content">
                        <p>
                          At EMB Metal Buildings, we offer an array of metal
                          building options, including carports, garages, barns,
                          sheds, and more. Our sales team is here to serve you
                          with the most competitive pricing and highest quality
                          of service. Before you commit to any purchase from us,
                          please read this pricing disclaimer so that you are
                          comfortable with the cost structure for our products
                          and services.
                        </p>

                        <p>
                          In order to provide you with the most reliable pricing
                          estimate, we consider a variety of factors. Steel
                          market prices, geographical location, building type
                          and size, customizations, roof style, building
                          certification, foundation, and the use of metal
                          buildings, are all taken into account when calculating
                          our price quotes. Our team of experienced
                          professionals will take the time to understand your
                          requirements, limitations, and budget, helping you
                          understand the scope, implications, and trade-offs of
                          your different options before providing a competitive
                          quote.
                        </p>

                        <p>
                          We would like to mention that a price quote is the
                          base price and that there may be other costs involved
                          in the project that must be taken into account before
                          you receive an accurate estimate of the total project
                          cost. Moreover, the quoted price is not guaranteed to
                          remain the same, as it may change depending on the
                          current market conditions and other factors.
                        </p>

                        <p>
                          If you have any concerns or questions regarding our
                          pricing, please feel free to give us a call at{" "}
                          <a href="tel:4792235995" aria-label="phone number">
                            (479) 223-5995
                          </a>
                          . Our experienced staff are always ready to help you
                          with your questions, and to make sure you are getting
                          the best prices available. Thank you for choosing EMB
                          Metal Buildings for your metal building project!
                        </p>
                      </TermsContent>
                    </>
                  )}

                  <div className="form-check">
                    <span className="form-check-text">
                      I understand that the price may vary.
                      <button type="button" onClick={ToggleTerms}>
                        Read Pricing Disclaimer
                      </button>
                    </span>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="terms"
                      onChange={(e) => setTermCheck(e.target.checked)}
                    />
                    <label className="form-check-label" htmlFor="terms"></label>
                  </div>
                </Terms>
              )}
              <PriceAction term={termCheck && cartData && cartData.length}>
                <Link to="/checkout/">
                  <DefaultButton text="Checkout" />
                </Link>
              </PriceAction>
            </CardDefaultBody>
          </CardDefault>
        </div>
      </CartRightPanel>
    </CartPanel>
  );
};

const CartPage = ({ location }) => {
  const breadcrumbSchema = {
    "@context": "https://schema.org/",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        name: "Home Page",
        item: "https://www.engineeredmetalbuildings.com/",
      },
      {
        "@type": "ListItem",
        position: 2,
        name: "Cart",
        item: `https://www.engineeredmetalbuildings.com/cart/`,
      },
    ],
  };
  const schema = [breadcrumbSchema];
  return (
    <Layout location={location}>
      <Seo title="Cart" description="Cart" schemaMarkup={schema} />
      <Section
        ept="100px"
        epb="0"
        xpt="100px"
        xpb="0"
        pt="64px"
        pb="0"
        bgColor="#fff"
        className="section-cart"
      >
        <Container>
          <CartList />
        </Container>
      </Section>
    </Layout>
  );
};
export default CartPage;
